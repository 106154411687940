import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { getIsLoggedIn } from './services/authService'
import './GlobalVariable/globalVariable'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./pages/Login'))
const isAuthenticated = getIsLoggedIn();

const App = () => {
  useEffect(() => {
          const handleLogoutInOtherTabs = () => {
            window.addEventListener('storage', (event) => {
              if (event.key === 'logoutToken') {
                window.location.reload();
              }
              if (event.key === 'loginToken') {
                window.location.reload();
              }
            });
          };
      
          handleLogoutInOtherTabs();
        }, []);
  return (
    <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            {(isAuthenticated || localStorage.getItem('googletoken') != null)
              ? <Route path="*" element={<DefaultLayout />}></Route>
              : <Route path="*" element={<Login />}></Route>
            }
          </Routes>
        </Suspense>
      </BrowserRouter>
  )
}

export default App


// // Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// const Login = React.lazy(() => import('./pages/Login'))
// const isAuthenticated = getIsLoggedIn();

// class App extends Component {
//   render(): JSX.Element {

//     useEffect(() => {
//       const handleLogoutInOtherTabs = () => {
//         window.addEventListener('storage', (event) => {
//           if (event.key === 'logoutToken') {
//             window.location.reload();
//           }
//           if (event.key === 'loginToken') {
//             window.location.reload();
//           }
//         });
//       };
  
//       handleLogoutInOtherTabs();
//     }, []);

//     return (
//       <BrowserRouter>
//         <Suspense fallback={<CSpinner color="primary" />}>
//           <Routes>
//             {(isAuthenticated || localStorage.getItem('googletoken') != null)
//               ? <Route path="*" element={<DefaultLayout />}></Route>
//               : <Route path="*" element={<Login />}></Route>
//             }
//           </Routes>
//         </Suspense>
//       </BrowserRouter>
//     )
//   }
// }

// export default App
