/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
import msalConfig from '../configs/msalConfig'
import {
  UserAgentApplication,
  AuthResponse,
  Logger,
  LogLevel,
  Account,
} from 'msal'
import { useState } from 'react'
import { REACT_APP_LOGOUT } from 'src/GlobalVariable/globalVariable'
const {
  instance,
  tenant,
  clientId,
  postLogoutRedirectUri,
  cacheLocation,
  redirectUri,
} = msalConfig

export const msalApp = new UserAgentApplication({
  auth: {
    clientId,
    // authority:'https://login.microsoftonline.com/4b009f47-da70-4650-90f5-509f3c6cf8e8/oauth2/authorize',
    authority: 'https://login.microsoftonline.com/common',
    // authority: instance + tenant,
    //authority: 'https://monitzion-weu-devi-adminuiproxy.azurewebsites.net',
    // authority:'https://monitzion.com/Monitzion%20Solution%20Admin/user_impersonation',

    navigateToLoginRequestUrl: false,
    //postLogoutRedirectUri:'https://testadminportal.monitzionsolutions.com/login', //--For Test
    //postLogoutRedirectUri: 'https://adminportal.monitzionsolutions.com/login', //--For Production
    postLogoutRedirectUri:REACT_APP_LOGOUT,
    redirectUri,
  },
  cache: {
    cacheLocation,
    storeAuthStateInCookie: true,
  },
  system: {
    logger: new Logger(
      (level: LogLevel, msg: string) => {
        if (level === LogLevel.Error) {
          console.error(msg)
        } else {
          console.warn(msg)
        }
      },
      { level: LogLevel.Warning, piiLoggingEnabled: false },
    ),
  },
})

export class AcquireTokenError extends Error {
  constructor() {
    super('Failed to acquire access token for back-end API')
    this.name = 'acquire_token_failed'
  }
}

const getApiScopes = () => {
  
  // constructs scopes e.g. api-client-id/Something.Read
  // this is the expected format by AAD v2
  const apiId = msalConfig.serviceClientId
  //console.log(apiId)
  const scopes = msalConfig.serviceScopes.split(' ')
  //console.log(scopes)
  //console.log('return data ' + scopes.map((s) => `${apiId}${s}`))
  //return ["\"a90bbfce-2838-4e60-9271-c85b61d7a2a4.default\""]
  //return scopes.map((s) => `${apiId}/${s}`)
  return ['a90bbfce-2838-4e60-9271-c85b61d7a2a4/.default']
}

export const fetchServiceToken = async () => {
  try {
    //Google Authentication Process
    if (localStorage.getItem('googletoken') != null) {
      let googletoken = null
      googletoken = localStorage.getItem('googletoken')
      return googletoken
    } else {
      const response: AuthResponse = await msalApp.acquireTokenSilent({
        scopes: getApiScopes(),
      })
      const value = response.accessToken;
      //sessionStorage.setItem("microaccessToken",value);
      return response.accessToken
    }
  } catch (e) {
    console.warn('fetchServiceToken failed', e)
    throw new AcquireTokenError()
  }
}

export const login = () => {
  msalApp.loginRedirect({
    scopes: getApiScopes(),
  })
  localStorage.setItem('microsoft', 'microsoftLogin')
}
export const getUser = () => {
  //Google Authentication Process
  if (localStorage.getItem('googletoken') != null) {
    const account = new Account(
      localStorage.getItem('email'),
      localStorage.getItem('email'),
      localStorage.getItem('email'),
      localStorage.getItem('userName'),
      {},
      null,
      null,
    )
    return account
  } else {
    return msalApp.getAccount()
  }
}

export const getIsLoggedIn = () => {
  return Boolean(getUser())
}

export const logout = () => {
  msalApp.logout()
}
