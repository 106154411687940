global.itemsPerPageDefaultValue = 20

export enum ServerPostLogoutRedirectUri {
  Localhost = 'http://localhost:1337/login',
  Production = 'https://adminportal.monitzionsolutions.com/login',
  Test = 'https://testadminportal.monitzionsolutions.com/login',
}

export enum ServerApiUri {
  Localhost = 'http://localhost:10586',
  Production = 'https://monitzion-weu-prod-apim.azure-api.net',
  Test = 'https://monitzion-weu-test-apim.azure-api.net',
}

export enum ServerObj {
  Localhost = 'Localhost',
  Production = 'Production',
  Test = 'Test',
}

export const ServerName: ServerObj = ServerObj.Test //Set For Server

export const REACT_APP_LOGOUT: ServerPostLogoutRedirectUri =
  ServerPostLogoutRedirectUri[ServerName]
export const REACT_APP_API_URL: ServerApiUri = ServerApiUri[ServerName]